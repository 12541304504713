import { render, staticRenderFns } from "./CustomSpan.vue?vue&type=template&id=44cf8ac1&"
import script from "./CustomSpan.vue?vue&type=script&lang=js&"
export * from "./CustomSpan.vue?vue&type=script&lang=js&"
import style0 from "./CustomSpan.vue?vue&type=style&index=0&id=44cf8ac1&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VIcon,VTooltip})
