import { render, staticRenderFns } from "./RolesPermissionsAssignPage.vue?vue&type=template&id=b4c30102&"
import script from "./RolesPermissionsAssignPage.vue?vue&type=script&lang=js&"
export * from "./RolesPermissionsAssignPage.vue?vue&type=script&lang=js&"
import style0 from "./RolesPermissionsAssignPage.vue?vue&type=style&index=0&id=b4c30102&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VCard,VCardTitle,VSpacer,VToolbar})
