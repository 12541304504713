var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-navigation-drawer',{attrs:{"absolute":"","temporary":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list-item',{staticClass:"white--text",style:({
        background:
          _vm.$vuetify.theme.themes[_vm.$vuetify.theme.dark ? 'dark' : 'light']
            .primary,
      })},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"d-flex justify-left align-center text-h6"},[_c('v-img',{attrs:{"lazy-src":_vm.compressedLogoUrl,"max-height":"30","max-width":"30","src":_vm.logoUrl},on:{"click":function($event){return _vm.$router.push(_vm.RoutesConfig.default.path)}}}),_c('span',{staticClass:"ml-3"},[_vm._v("The 95 Star")])],1)],1)],1),_c('v-divider'),_c('v-list',{attrs:{"nav":"","dense":""}},[_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((_vm.tabs),function(tab,index){return _c('v-list-item',{key:index,attrs:{"to":tab.path}},[_c('v-list-item-title',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(tab.title)+" ")])],1)}),1)],1)],1),_c('v-app-bar',{attrs:{"app":"","color":_vm.$vuetify.theme.dark ? '' : 'white',"elevation":"2","flat":""}},[(_vm.$vuetify.breakpoint.smAndDown)?_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}):_vm._e(),_c('v-img',{attrs:{"lazy-src":_vm.compressedLogoUrl,"max-height":"40","max-width":"40","src":_vm.logoUrl},on:{"click":function($event){return _vm.$router.push(_vm.RoutesConfig.default.path)}}}),(!_vm.$vuetify.breakpoint.smAndDown)?_c('v-tabs',{attrs:{"align-with-title":""}},_vm._l((_vm.tabs),function(tab,index){return _c('v-tab',{key:index,attrs:{"to":tab.path}},[_vm._v(" "+_vm._s(tab.title)+" ")])}),1):_vm._e(),_c('v-spacer'),(!_vm.user.id)?_c('v-btn',{attrs:{"rounded":"","color":"primary"},on:{"click":function($event){_vm.isSignInModalOpen = true}}},[_vm._v(" Sign in ")]):_c('v-menu',{attrs:{"offset-x":"","left":"","transition":"slide-x-transition","min-width":"200px","rounded":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","x-large":""}},on),[_c('v-avatar',{attrs:{"color":"primary","size":"40"}},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.user.initials))])])],1)]}}])},[_c('v-card',[_c('v-list-item-content',{staticClass:"justify-center"},[_c('div',{staticClass:"mx-auto text-center"},[_c('v-avatar',{staticClass:"mb-3",attrs:{"color":"primary"}},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.user.initials))])]),_c('h3',{staticStyle:{"max-width":"230px"}},[_vm._v(" "+_vm._s(_vm.user.fullName)+" ")]),_c('p',{staticClass:"text-caption mt-1"},[_vm._v(" "+_vm._s(_vm.user.email)+" ")]),(_vm.hasPermission(_vm.PERMISSIONS.usersEdit))?_c('v-divider',{staticClass:"my-3"}):_vm._e(),(_vm.hasPermission(_vm.PERMISSIONS.usersEdit))?_c('v-btn',{attrs:{"depressed":"","text":""},on:{"click":function($event){return _vm.$router.push(("/users/" + (_vm.user.id) + "/profile-info"))}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-pencil ")]),_vm._v(" Edit Account ")],1):_vm._e(),(_vm.hasPermission(_vm.PERMISSIONS.settingsView))?_c('div',[(_vm.hasPermission(_vm.PERMISSIONS.settingsView))?_c('v-divider',{staticClass:"my-3"}):_vm._e(),_c('v-btn',{attrs:{"depressed":"","text":""},on:{"click":function($event){_vm.$router.push(
                    _vm.hasPermission(_vm.PERMISSIONS.rolesView)
                      ? _vm.RoutesConfig.settings.rolesPath
                      : _vm.RoutesConfig.settings.configurationPath
                  )}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-cog ")]),_vm._v(" settings ")],1)],1):_vm._e(),_c('v-divider',{staticClass:"my-3"}),_c('div',{staticClass:"d-flex justify-center"},[_c('v-switch',{attrs:{"inset":"","label":"Dark Mode"},on:{"click":function($event){return _vm.onThemeChange()}},model:{value:(_vm.$vuetify.theme.dark),callback:function ($$v) {_vm.$set(_vm.$vuetify.theme, "dark", $$v)},expression:"$vuetify.theme.dark"}})],1),_c('v-divider',{staticClass:"mb-3"}),_c('v-btn',{attrs:{"depressed":"","text":""},on:{"click":function($event){return _vm.onSignOut()}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-logout ")]),_vm._v(" Signout ")],1)],1)])],1)],1)],1),_c('login-modal',{attrs:{"open":_vm.isSignInModalOpen},on:{"close":function($event){_vm.isSignInModalOpen = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }