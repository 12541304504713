var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._g(_vm._b({ref:"customDataTable",attrs:{"id":_vm.id,"mobile-breakpoint":"100","headers":_vm.computedHeaders.concat( [{
      text: '',
      value: 'settings',
      sortable: false,
      width: '20px',
    }] )},scopedSlots:_vm._u([(_vm.dataTableSetting)?{key:"header.settings",fn:function(props){return [_c('data-table-setting',{attrs:{"headers":_vm.headersWithoutActions,"title":_vm.title},model:{value:(_vm.selectedHeaders),callback:function ($$v) {_vm.selectedHeaders=$$v},expression:"selectedHeaders"}})]}}:null,{key:"item.$$default",fn:function(ref){
  var item = ref.item;
  var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.getValue({ item: item, header: header }))+" ")]}},{key:"item.$$percentage",fn:function(ref){
  var item = ref.item;
  var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.getValue({ item: item, header: header, append: "%" }))+" ")]}},{key:"item.$$boolean",fn:function(ref){
  var item = ref.item;
  var header = ref.header;
return [_vm._v(" "+_vm._s(typeof header.getValue === "function" ? header.getValue({ item: item, header: header }) ? "Yes" : "No" : "N/A")+" ")]}},{key:"item.$$amount",fn:function(ref){
  var item = ref.item;
  var header = ref.header;
return [_c('amount-value',{attrs:{"amount":_vm.getValue({ item: item, header: header, defaultValue: 0 })}})]}},{key:"item.$$toggle",fn:function(ref){
  var item = ref.item;
  var header = ref.header;
return [_c('div',{staticClass:"is-active"},[_c('v-switch',{attrs:{"color":"primary","inset":""},on:{"change":function (e) { return _vm.$emit('on-toggle', e, item); }},model:{value:(item[header.valueFrom]),callback:function ($$v) {_vm.$set(item, header.valueFrom, $$v)},expression:"item[header.valueFrom]"}})],1)]}},{key:"item.$$redirectedTo",fn:function(ref){
  var item = ref.item;
  var header = ref.header;
return [(header.getPath(item).path)?_c('a',{on:{"click":function($event){_vm.redirectedToPath(header.getPath(item))}}},[_vm._v(" "+_vm._s(_vm.getValue({ item: item, header: header }))+" ")]):(header.getPath(item).value)?_c('span',[_vm._v(" "+_vm._s(_vm.getValue({ item: item, header: header }))+" ")]):_c('span',[_vm._v(" N/A ")])]}},{key:"item.$$chip",fn:function(ref){
  var item = ref.item;
  var header = ref.header;
return [_c('v-chip',{attrs:{"color":_vm.getStatusColor(item[header.valueFrom])}},[_vm._v(" "+_vm._s(_vm.getValue({ item: item, header: header, operations: ["titleCase"] }))+" ")])]}},{key:"item.$$date",fn:function(ref){
  var item = ref.item;
  var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.getValue( { item: item, header: header, operations: ["formatDate"] }, header.isDateOnly ))+" ")]}},{key:"item.$$convertCase",fn:function(ref){
  var item = ref.item;
  var header = ref.header;
return [(header.isTooltip)?_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"truncate"},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.getValueForToolTip({ item: item, header: header }))+" ")])]}}],null,true)},[_c('p',[_vm._v(" "+_vm._s(_vm.getValueForToolTip({ item: item, header: header }))+" ")])]):_c('span',[_vm._v(" "+_vm._s(_vm.getValueForToolTip({ item: item, header: header }))+" ")])]}},{key:"item.$$actions",fn:function(ref){
  var item = ref.item;
  var header = ref.header;
return [_c('custom-image-btn',{key:((item.id) + "_image-btn"),ref:("imageButton_" + (item.id)),attrs:{"id":("action-btn-" + (item.id)),"hidden":"","item":item,"show-btn":header.showUploadField},on:{"onUpload":function($event){return _vm.$emit('on-upload-image')}}}),_c('v-menu',{attrs:{"transition":"slide-y-transition","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pa-0 ma-0",attrs:{"text":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[(item.$$actions.length)?_c('v-list',[_vm._l((item.$$actions),function(action,i){return [_c('v-list-item',{key:i,staticClass:"ma-0",attrs:{"id":("action-btn-" + i),"block":"","dense":"","color":action.color},on:{"click":function($event){action.name === _vm.ActionDefaults.viewImage.name
                ? _vm.openImageComponent(item)
                : _vm.$emit('action-performed', { item: item, action: action, header: header })}}},[_c('span',{staticClass:"px-2"},[(action.icon)?_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(action.icon)+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.titleCase(action.title || action.name))+" ")],1)])]})],2):_c('v-list',[_c('v-list-item',[_vm._v(" No Action Available ")])],1)],1)]}},_vm._l((_vm.$scopedSlots),function(index,name){return {key:name,fn:function(data){return [_vm._t(name,null,null,data)]}}})],null,true)},'v-data-table',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }